.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.logo {
    width: 150px; /* Adjust size as needed */
    margin-bottom: 20px;
}
