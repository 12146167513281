@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes shine {
    0% {
      transform: translateY(0);
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0);
    }
    50% {
      transform: translateY(-5px);
      box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.8);
    }
    100% {
      transform: translateY(0);
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0);
    }
  }
  
  .animate-shine {
    animation: shine 2s infinite ease-in-out;
  }
  
  @keyframes slide {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
  
  .animate-slide {
    animation: slide 5s linear infinite;
  }
  
  .card-container {
    perspective: 1000px;
  }
  
  .flip-card {
    transition: transform 0.6s, opacity 0.6s;
    transform-style: preserve-3d;
  }
  
  .card-container.flipped .flip-card {
    transform: rotateY(180deg);
  }
  
  .card {
    width: 90px;
    height: 120px;
    backface-visibility: hidden;
    opacity: 0; /* Start hidden */
    transition: opacity 0.6s;
  }
  
  .card-container.flipped .card.show {
    opacity: 1; /* Show the card when flipped */
    transform: translateY(0); /* Ensure it stays in place */
  }
  
  .flip-card img {
    width: 90px;
    height: 120px;
    transform: translateY(-30px); /* Move the card up initially */
    transition: transform 0.6s; /* Animate the movement */
  }
  
  .card-container.flipped .flip-card {
    transform: rotateY(180deg) translateY(0); /* Card flips and settles */
  }
  

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes flash {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  .winner-animation {
    animation: bounce 2s infinite;
  }
  
  .animate-bounce {
    animation: bounce 2s infinite;
  }
  
  .animate-pulse {
    animation: pulse 1.5s infinite;
  }
  
  .animate-flash {
    animation: flash 1s infinite;
  }
  
  .winner-notification {
    color: gold;
    font-weight: bold;
    font-size: 24px;
  }
  



  @keyframes move-to-center {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(calc(50vw - 50%), calc(50vh - 50%));
      opacity: 0;
    }
  }
  
  .bet-animation {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    color: red;
    animation: move-to-center 10s ease-in-out forwards;
    z-index: 1000; 
  }
  
  .chip {
    width: 40px; /* Increase size if needed */
    height: 40px;
    background-color: orange;
    border-radius: 50%;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    line-height: 40px; /* Center the text inside */
  }
  


/* Card Dealing Animation */
.deal-animation {
  animation: dealCard 1s ease-out forwards;
}

@keyframes dealCard {
  0% {
    transform: translate(-50vw, -50vh) scale(0.5) rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) scale(1) rotateY(0deg);
    opacity: 1;
  }
}


  
.fade-message {
  animation: fadeOut 5s ease-in-out forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


