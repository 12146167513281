
/* Loading screen fade out */
@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /* Loading bar animation */
  @keyframes loadProgress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  
  .fade-out {
    animation: fadeOut 1s ease-out forwards;
  }
  
  .loading-bar {
    animation: loadProgress 3s linear forwards;
  }