.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.activation-container {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.activation-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.progress-bar {
    width: 300px;
    height: 20px;
    border-radius: 10px;
    margin: 0 auto 20px;
    overflow: hidden;
}

.progress-bar-inner {
    height: 100%;
    background-color: #007bff;
    border-radius: 10px;
    transition: width 0.5s ease-in-out;
}

.activation-text {
    font-size: 16px;
    color: #555;
}

.success-message {
    font-size: 18px;
    color: green;
    margin-top: 20px;
}

.error-message {
    font-size: 18px;
    color: red;
    margin-top: 20px;
}
